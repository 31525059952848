import axios from "axios";

export default {
  namespaced: true,

  state: {
    userData: null,
  },

  getters: {
    user: state => state.userData,
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    },
  },

  actions: {
    getUserData({ commit }) {
      axios
        .get(process.env.VUE_APP_API_URL + "user/data")
        .then(response => {
          commit("setUserData", response.data.data);
          // check if user activate/DeActivate
          if (response.data.data.user.status == 2) {
            this.$router.push({ name: "Login" });
          }
          if (response.data.data.user) {
            localStorage.setItem("user_last_name", response.data.data.user.last_name);
            localStorage.setItem("permissions", response.data.data.user.role_id);
          }
          else {
            localStorage.removeItem("user_last_name");
            localStorage.removeItem("permissions");
          }
        })
        .catch(() => {
          // localStorage.removeItem("authToken");
        });
    },
    sendLoginRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signin", data)
        .then(response => {
          if (response.data.statusCode === 200) {
            console.log('1', response.data.token)
            if (response.data.email_verification_status == 0) {
              commit("setUserData", response.data);
              dispatch("getUserData");
            console.log(response.data.token);
              localStorage.setItem("authToken", response.data.token);
              localStorage.setItem("permissions", response.data.user.role_id);
              localStorage.setItem("loginUserId", response.data.user.id);
              localStorage.setItem("shopifyStoreCount", response.data.shopify_store_count);
            }
            else {
              return response;
            }
          }
          return response;
        })
        .catch(error => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    sendRegisterRequest({ commit, dispatch }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signup", data)
        .then(response => {
          if (response.data.statusCode === 200) {
            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem("registerdUserId", response.data.data.userId);
            dispatch("getUserData");

          }
          return response;
        })
        .catch(error => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    sendResetPasswordRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "requestReset", data)
        .then(response => {
          localStorage.setItem("verifyEmail", data.email);
          return response;
        });
    },
    codeVerify({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "codeVerfy", data)
        .then(response => {
          localStorage.setItem("verifiedCode", data.code);
          return response;
        });
    },
    changePassword({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "passwordReset", data)
        .then(response => {
          localStorage.removeItem("verifyEmail");
          localStorage.removeItem("verifiedCode");
          return response;
        });
    },
    verifyEmail({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(process.env.VUE_APP_API_URL + `verifyEmail?code=${data.code}&email=${data.email}`)
        .then(response => {
          return response;
        });
    },
    sendLogoutRequest({ commit }) {
      return axios.post(process.env.VUE_APP_API_URL + "auth/signout")
        .then(response => {
          if (response.data.statusCode === 200) {
            commit("setUserData", null);
            localStorage.removeItem("authToken");
            localStorage.removeItem("permissions");
          }
          return response;
        });
    },


  }
};
